.counter-badge {
    font-size: 14px;
    background-color: var(--color-attention);
    color: #ffffff;
    padding: 2px 4px;
    line-height: 14px;
    border-radius: 9px;
    min-width: 18px;
    text-align: center;
    display: inline-block;
}
