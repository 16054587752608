@use '../../../css/variables.scss';

.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 16px;

    &--small {
        grid-gap: 8px;
    }

    &--large {
        grid-gap: 20px;
    }

    &--xl-row {
        grid-row-gap: 32px;
    }

    &--xxl-row {
        grid-row-gap: 48px;
    }

    @for $i from 1 through 12 {
        &__col-#{$i} {
            grid-column: span $i;
        }
    }

    @each $breakpoint, $minWidth in variables.$breakpoints {
        @media (min-width: $minWidth) {
            @for $i from 1 through 12 {
                &__col-#{$i}--#{$breakpoint} {
                    grid-column: span $i;
                }
            }
        }
    }
}
