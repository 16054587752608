@use '../../../css/variables';
@use '../../../css/functions';

$menu-transition-duration: 0.5s;
$menu-transition-timing: ease-in-out;

%toggle {
    position: absolute;
    display: block;
    width: 40px;
    height: 40px;
    padding: 4px;
    appearance: none;
    border: none;
    outline: none;
    background-color: var(--color-brand-primary);
    $mask-image: functions.url-encode('<svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" stroke="#000" fill="none" stroke-linecap="round" stroke-linejoin="round"><path d="m1 2 15 15L31 2" stroke-width="2"/><path d="m1 15 15 15 15-15" stroke-width="2"/></svg>');
    mask-image: url($mask-image);
    mask-size: 32px;
    mask-repeat: no-repeat;
    mask-position: center;
    transition: transform $menu-transition-duration $menu-transition-timing;
    z-index: 2;
    cursor: pointer;

    @media (min-width: variables.$break-md) {
        display: none;
    }
}

.main-menu {
    position: relative;
    --menu-item-padding: 8px;

    @media (min-width: variables.$break-md) {
        --menu-item-padding: 4px;
    }

    @media (min-width: variables.$break-lg) {
        --menu-item-padding: 8px;
    }

    &::before {
        display: none;
        position: fixed;
        background-color: #ffffff;
        width: 100%;
        height: 83px;
        top: 0;
        left: 0;
        content: '';
        z-index: 2;

        @media (min-width: variables.$break-md) {
            display: none;
        }
    }

    &.is-open,
    &.is-closing {
        &::before {
            display: block;
        }
    }

    &__toggle {
        @extend %toggle;

        right: 0;
    }

    &.is-open &__toggle {
        transform: rotateX(180deg);
    }

    &__container {
        height: calc(100vh - 67px);
        width: 100vw;
        position: fixed;
        top: 67px;
        left: 0;
        padding: 16px 16px 0;
        background-color: #ffffff;
        transform: translateY(calc(-100% - 67px));
        z-index: 1;

        @media (min-width: variables.$break-md) {
            position: static;
            padding: 0;
            transform: none;
            height: auto;
            width: auto;
        }
    }

    &__list {
        @extend %reset-list;
        width: 100%;
        display: flex;
        flex-flow: row wrap;
        justify-content: flex-start;
        align-content: flex-start;

        @media (min-width: variables.$break-md) {
            flex-wrap: nowrap;
        }
    }

    @media (max-width: #{variables.$break-md - 1}) {
        &.is-animated &__container,
        &.is-animated &__list {
            transition-property: top, transform;
            transition-duration: $menu-transition-duration;
            transition-timing-function: $menu-transition-timing;
        }

        &.is-open &__container {
            transform: translateY(0);

            &::after {
                content: '';
                display: block;
                position: absolute;
                left: 100%;
                height: 100%;
                width: 32px;
                background-color: #ffffff;
            }
        }

        &.is-sub-open &__list {
            transform: translateY(-100%) translateX(-100vw);
        }

        &.is-open.is-sub-open &__list {
            transform: translateY(0) translateX(-100vw);
        }
    }

    &__item {
        width: 96px;
        height: 100px;
        color: var(--color-brand-dark);
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        border-radius: 3px;
        padding: var(--menu-item-padding);
        text-align: center;

        --icon-color: var(--color-brand-primary);

        transition-property: background-color, color, --icon-color;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &.is-active,
        &:hover {
            background-color: var(--color-brand-primary);
            color: #ffffff;
            --icon-color: #ffffff;
        }

        &.is-disabled {
            background-color: transparent;
            color: var(--color-brand-light);
            --icon-color: var(--color-brand-light);
            pointer-events: none;
        }

        .counter-badge {
            position: absolute;
            top: 4px;
            right: 4px;
            z-index: 1;
        }

        @media (min-width: variables.$break-md) {
            width: 92px;
            height: 96px;
            position: relative;

            &--right {
                margin-left: auto !important;
                justify-self: flex-end;
            }

            &--hidden-md {
                display: none;
            }
        }

        @media (min-width: variables.$break-lg) {
            width: 96px;
            height: 100px;
            border-radius: 0 0 3px 3px;
        }
    }

    &__link {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        font-size: 12px;
        line-height: 1.25;

        width: calc(100% + var(--menu-item-padding) * 2);
        height: calc(100% + var(--menu-item-padding) * 2);
        margin: calc(var(--menu-item-padding) * -1);
        padding: var(--menu-item-padding);

        color: inherit;
        text-decoration: none;

        @media (min-width: variables.$break-md) {

        }
    }

    &__icon-wrapper {
        position: relative;
    }

    &__icon {
        display: block;
        width: 80px;
        height: 56px;
        background-color: var(--icon-color);
        mask-position: center;
        mask-repeat: no-repeat;
    }

    &__label {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    &__sub-toggle {
        @extend %toggle;
        display: none;
        top: 0;
        left: calc(100vw - 4px);
        transform: rotateZ(90deg);
    }

    .is-open > &__sub-toggle {
        display: block;

        @media (min-width: variables.$break-md) {
            display: none;
        }
    }

    &__sub-container {
        position: absolute;
        width: 100%;
        left: 100vw;
        top: 44px;
        height: calc(100vh - 67px - 16px - 44px); // screen - header - gap - back-button
        display: none;
        background-color: #ffffff;
        overflow: auto;

        @media (min-width: variables.$break-md) {
            top: calc(100% - 6px);
            left: 0;
            padding: 0;
            box-shadow: 0 10px 15px #00000036;
            border-radius: 3px;
            width: auto;
            z-index: 1;
            height: auto;
        }
    }

    &__sub-list {
        @extend %reset-list;

        .main-menu__sub-toggle {
            display: block;
            top: 0;
            right: 0;
            width: 32px;
            left: auto;
            height: 32px;
            transform: none;
            mask-size: 20px;

            @media (min-width: variables.$break-md) {
                display: none;
            }
        }

        .is-open > .main-menu__sub-toggle {
            transform: rotateX(180deg);
        }

        & & {
            display: none;
            position: static;
            padding-top: 0;
            box-shadow: none;
            padding-left: 16px;

            @media (min-width: variables.$break-md) {
                display: none;
            }
        }
    }

    .is-open > &__sub-container {
        display: block;
    }

    .is-open > &__sub-list {
        display: block;

        @media (min-width: variables.$break-md) {
            .main-menu__sub-list {
                display: none;
            }
        }
    }

    &__sub-item {
        color: var(--color-brand-dark);
        position: relative;

        &.is-active > a {
            font-weight: variables.$font-weight-bold;
        }

        &:hover > a {
            background-color: var(--color-grey-light);
        }

        @media (min-width: variables.$break-md) {
            padding: 0 16px;
            display: flex;
            flex-flow: row wrap;

            &:hover {
                background-color: var(--color-grey-light);

                > a {
                    background-color: transparent;
                }
            }
        }
    }

    &__sub-link {
        color: inherit;
        text-decoration: none;
        display: flex;
        height: 32px;
        justify-content: flex-start;
        align-items: center;
        white-space: nowrap;
    }
}

body.is-menu-open {
    overflow: hidden;
}
