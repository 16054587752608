ul,
ol {
  margin-block-end: 1em;
  padding-inline-start: 1.25em;
}

ol {
  li {
    padding-inline-start: 0.375em;
  }
}
