@use '../../../css/functions.scss';

$arrow-down: functions.url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M7.406 8.578l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z"></path></svg>');

.agent-menu {
    position: relative;

    .form__control {
        padding-right: 30px;
        position: relative;
        background-color: #ffffff;
        z-index: 1;

        &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            position: absolute;
            top: calc(50% - 10px);
            right: 5px;
            mask-image: url($arrow-down);
            background-color: var(--field-highlight-color);
        }
    }

    &__list {
        @extend %reset-list;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
        border-radius: 0 0 3px 3px;
        position: absolute;
        top: 100%;
        background-color: #ffffff;
        display: none;
        min-width: 100%;
    }

    &__item {
        &:hover {
            background-color: var(--color-grey);
        }
    }

    &__link {
        display: block;
        text-decoration: none;
        padding: 5px 10px;
        line-height: 20px;
        width: max-content;
    }

    &:hover,
    &:focus {
        .agent-menu__list {
            display: block;
        }
    }
}
