@use '../../../css/variables.scss';
@use '../../../css/functions.scss';

.table-wrapper {
    overflow-x: auto;
}

table {
    border-collapse: collapse;

    .button-row {
        flex-wrap: nowrap;
    }

    &.table--wide {
        width: 100%;
    }
}

th {
    white-space: nowrap;
}

th,
td {
    padding-block: 0.25em;
    padding-inline: 0.5em;
    border: none;

    &.narrow {
        width: 0;
    }
}

th {
    text-align: start;
    background: var(--color-brand-primary);
    color: #ffffff;
    font-weight: normal;
}

.unread-indicator {
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--color-attention);
}

.table--actions {
    tr {
        color: var(--color-brand-dark);
        font-weight: variables.$font-weight-bold;

        .download {
            display: inline-flex;
            flex-flow: row nowrap;
            align-items: center;

            &::before {
                $mask-image: functions.url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#000000" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z" /></svg>');
                content: '';
                width: 16px;
                height: 20px;
                display: block;
                background-color: var(--color-brand-dark);
                mask: url($mask-image) center no-repeat;
                mask-size: 24px;
                margin-right: 8px;
                flex-shrink: 0;
            }
        }

        &.is-read {
            color: var(--color-grey-dark);
            font-weight: variables.$font-weight-regular;

            .button {
                --button-icon-color: var(--color-grey-dark);
            }

            .download::before {
                background-color: var(--color-grey-dark);
            }
        }
    }
}
