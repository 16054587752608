@use '../../../css/variables';
@use '../../../css/functions';

.form {
    &__field {
        --field-border-color: var(--field-default-border-color, var(--color-brand-dark));
        --field-background-color: var(--field-default-background-color, transparent);
        --field-text-color: var(--field-default-text-color, var(--color-brand-dark));
        --field-label-color: var(--field-default-label-color, var(--color-brand-dark));
        --field-placeholder-color: var(--field-default-placeholder-color, var(--color-brand-primary));
        --field-focus-border-color: var(--field-default-focus-border-color, var(--color-brand-light));

        display: flex;
        flex-flow: column;
        color: var(--field-text-color);

        &.has-error {
            --field-border-color: var(--field-error-border-color, var(--color-attention));
            --field-label-color: var(--field-error-label-color, var(--color-attention));
        }
    }

    &__label {
        margin-bottom: 4px;
        font-weight: variables.$font-weight-bold;
        font-size: 12px;
        line-height: 20px;
        color: var(--field-label-color);
    }

    &__control {
        border: 1px solid var(--field-border-color);
        border-radius: 3px;
        background-color: var(--field-background-color);
        min-height: 37px;
        padding: 8px;
        color: var(--field-text-color);
        font-family: inherit;
        font-size: inherit;

        &::placeholder {
            font-family: inherit;
            font-size: inherit;
            color: var(--field-placeholder-color);
        }

        &:focus {
            outline: none;
            border-color: var(--field-focus-border-color);
        }
    }

    &__error {
        color: var(--color-attention);
        font-size: 12px;
        font-weight: variables.$font-weight-bold;
        margin: 4px 0 0;
    }

    &__help {
        font-size: 12px;
        font-weight: variables.$font-weight-bold;
        margin: 4px 0 0;
    }
}

textarea.form__control {
    min-height: 100px;
}

select,
.select {
    &.form__control {
        appearance: none;
        padding-right: 24px;
        position: relative;
        $arrow-down: functions.url-encode('<svg viewBox="0 0 134 71" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="m4.2 4.2 62.5 62.5 62.5-62.5" fill="none" stroke="#{variables.$color-brand-dark}" stroke-width="8.3"/></svg>');
        background: url($arrow-down) no-repeat center right 6px;
        background-size: 18px;
    }
}

.form-fieldset {
    --padding: 1em;

    padding: calc(var(--padding) / 2);
    border: 0.0625em solid var(--color-gray-300);
}

.form-fieldset__legend {
    padding-inline: calc(var(--padding) / 2);
    font-weight: 700;
    font-size: 1.125em;
}

.form-fieldset__content {
    display: grid;
    gap: 1em;
    padding: calc(var(--padding) / 2);
}

@mixin checkbox-or-radio($type) {
    .#{$type} {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        padding: 5px 0;
        @if $type == "radio" {
            --outer-border-radius: 50%;
            --inner-border-radius: 50%;
        } @else {
            --outer-border-radius: 3px;
            --inner-border-radius: 2px;
        }

        &__label {
            line-height: 20px;
        }

        &__control {
            visibility: hidden;
            position: absolute;
            pointer-events: none;
        }

        &__indicator {
            display: block;
            width: 20px;
            height: 20px;
            border: 1px solid var(--color-brand-dark);
            border-radius: var(--outer-border-radius);
            @if $type == "radio" {
                padding: 4px;
            } @else {
                padding: 3px;
            }
            margin-right: 8px;
            flex-shrink: 0;
        }

        &__control:checked ~ &__indicator &__checked {
            display: block;
            width: 100%;
            height: 100%;
            background-color: var(--color-brand-primary);
            border-radius: var(--inner-border-radius);
        }
    }

    .#{$type}-group {
        display: flex;
        flex-flow: column;
        margin-right: auto;
    }

}

@include checkbox-or-radio('checkbox');
@include checkbox-or-radio('radio');

.file-upload {
    padding: 0;
    display: flex;
    flex-flow: row nowrap;
    position: relative;
    line-height: 1;

    &__control {
        visibility: hidden;
        position: absolute;
        pointer-events: none;
    }

    &__label {
        border-radius: 0;
        padding: 9px;
        min-width: auto;
        display: flex;
        align-items: center;
        flex-shrink: 0;
    }

    &__file-name {
        padding: 9px;
        box-shadow: 0 0 10px -3px var(--color-brand-light) inset;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &.is-drop-enabled::before {
    }

    &__drop-indicator {
        width: calc(100% - 8px);
        height: calc(100% - 8px);
        position: absolute;
        margin: 4px;
        border: 3px dashed var(--color-text);
        border-radius: 3px;
        padding: 4px;
        flex-flow: row nowrap;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.05em;
        color: var(--color-text);
        display: none;
        pointer-events: none;
    }

    &.is-drop-enabled {
        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background-color: #ffffff;
            opacity: 0.7;
            pointer-events: none;
        }

        .file-upload__drop-indicator {
            display: flex;
        }
    }
}
