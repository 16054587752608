@use '../../../css/variables.scss';

.form {
    &__block {
        display: grid;
        grid-gap: variables.$form-grid-gap;
    }

    &__row {
        display: grid;
        grid-gap: variables.$form-grid-gap;
        grid-template-columns: repeat(12, 1fr);

        > * {
            grid-column: span 12;
        }

        @for $i from 1 through 12 {
            .form__column--#{$i} {
                grid-column: span $i;
            }
        }

        @media (min-width: variables.$break-sm) {
            @for $i from 1 through 12 {
                .form__column--sm-#{$i} {
                    grid-column: span $i;
                }
            }
        }

        @media (min-width: variables.$break-md) {
            @for $i from 1 through 12 {
                .form__column--md-#{$i} {
                    grid-column: span $i;
                }
            }
        }

        @media (min-width: variables.$break-lg) {
            @for $i from 1 through 12 {
                .form__column--lg-#{$i} {
                    grid-column: span $i;
                }
            }
        }

        @media (min-width: variables.$break-xl) {
            @for $i from 1 through 12 {
                .form__column--xl-#{$i} {
                    grid-column: span $i;
                }
            }
        }
    }

    &__actions {
        margin-top: variables.$form-block-margin;
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;

        :nth-child(2) {
            margin-left: auto;
            justify-self: flex-end;
        }

        &--right {
            justify-content: flex-end;
        }
    }

    &--theme-filled {
        --field-default-border-color: var(--color-grey-light);
        --field-default-background-color: var(--color-grey-light);
        --field-default-text-color: var(--color-brand-dark);
        --field-default-label-color: var(--color-brand-dark);
        --field-default-placeholder-color: var(--color-grey-dark);
        --field-default-focus-border-color: var(--color-brand-primary);
    }
}
