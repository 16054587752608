.nav-tools {
  color: var(--color-gray-600);

  background: var(--color-brand-background);
  border: 0.0625em solid var(--color-gray-300);
}

.nav-tools-lang {
  display: flex;
  gap: 0.5em;
  padding-block: 0.5em;
  padding-inline: 1em;
}

.nav-tools-lang__list {
  list-style: none;

  display: flex;
  gap: 0.5em;
  margin: 0;
  padding: 0;
}

.nav-tools-lang__item {
  &.is-current {
    font-weight: 700;
  }
}

.nav-tools-lang__link {
  color: inherit;
}
