@use '../../../css/variables';
@use '../../../css/functions';

$arrow-right: functions.url-encode('<svg viewBox="0 0 17 32" xmlns="http://www.w3.org/2000/svg" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="m1 31 15-15L1 1" fill="none" stroke="#000" stroke-width="2"/></svg>');

.page-tree {
    line-height: 24px;
    overflow-x: auto;

    &__tree {
        margin-top: 16px;
    }

    &__list {
        @extend %reset-list
    }

    &__item {
        position: relative;
        padding: 4px 0 4px 22px;
    }

    &__icon {
        position: absolute;
        top: 10px;
        left: 1px;
        background-color: var(--color-brand-dark);
        mask: url($arrow-right) no-repeat center center;
        mask-size: 7px 14px;
        width: 20px;
        height: 20px;
    }

    &__item.is-open.has-children &__icon {
        transform: rotateZ(90deg);
    }

    &__link {
        justify-content: flex-start;
        align-items: center;
        display: flex;
        text-decoration: none;
        color: inherit;
    }

    &__list &__list {
        margin-left: 8px;
        display: none;
    }

    &__item.is-open > &__list {
        display: block;
    }
}
