@use '../../../css/variables.scss';

.teaser {
    background-color: var(--color-grey-light);
    padding: 20px 25px;
    overflow: auto;

    &__title {
        margin-bottom: 10px;
    }

    a {
        color: var(--color-brand-dark);
        font-weight: variables.$font-weight-bold;
    }
}
