@use "@fontsource/lato/scss/mixins" as lato;

@include lato.fontFace(
  $weight: 300,
  $style: normal,
  $display: fallback
);

@include lato.fontFace(
  $weight: 400,
  $style: normal,
  $display: fallback
);

@include lato.fontFace(
  $weight: 400,
  $style: italic,
  $display: fallback
);

@include lato.fontFace(
  $weight: 700,
  $style: normal,
  $display: fallback
);

@include lato.fontFace(
  $weight: 700,
  $style: italic,
  $display: fallback
);
