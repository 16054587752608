@use '../../../css/variables.scss';
@use '../../../css/functions.scss';

.language-menu {
    color: var(--color-brand-primary);

    &__list {
        @extend %reset-list;
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        justify-content: space-between;
        justify-items: center;
        margin: -7px 0 -7px 0;

        @media (min-width: variables.$break-md) {
            padding-left: 14px;
        }
    }

    &__item {
        width: 100%;
        height: 30px;

        &.is-active {
            font-weight: variables.$font-weight-bold;
            color: var(--color-brand-dark);
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        text-decoration: none;
        color: inherit;
    }
}
