@use '../../../css/variables.scss';
@use '../../../css/functions.scss';

.button {
    display: inline-block;
    padding: 0.75em 1.75em;
    line-height: 1;
    border-radius: 3px;
    text-decoration: none;
    text-align: center;
    transition-property: color, border-color, background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease-in-out;
    cursor: pointer;
    font-size: inherit;
    font-family: inherit;

    --button-color: var(--color-brand-dark);
    --button-border-color: var(--color-brand-dark);
    --button-bg-color: transparent;
    --button-hover-color: #ffffff;
    --button-hover-border-color: var(--color-brand-primary);
    --button-hover-bg-color: var(--color-brand-primary);
    --icon-color: var(--button-icon-color);
    --icon-bg-color: var(--button-bg-color);

    color: var(--button-color, var(--color-brand-dark));
    border: 1px solid var(--button-border-color);
    background: var(--button-bg-color);

    @media (min-width: variables.$break-lg) {
        min-width: 186px;
    }

    &:hover {
        color: var(--button-hover-color);
        border-color: var(--button-hover-border-color);
        background-color: var(--button-hover-bg-color);
        --icon-color: var(--button-hover-color);
        --icon-bg-color: var(--button-hover-bg-color);
    }

    &--primary {
        --button-color: #ffffff;
        --button-border-color: var(--color-brand-primary);
        --button-bg-color: var(--color-brand-primary);
        --button-hover-border-color: var(--color-brand-dark);
        --button-hover-bg-color: var(--color-brand-dark);
        --icon-color: var(--button-color);
    }

    &--caps {
        text-transform: uppercase;
    }

    &--flex {
        @media (min-width: variables.$break-lg) {
            min-width: auto;
        }
    }

    &--icon {
        display: inline-flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 0.25em 0.75em;
        min-width: 0;

        .icon {
            margin-right: 8px;
        }
    }

    &--icon-only {
        padding: 0.25em;
        --button-border-color: transparent;

        .icon {
            margin-right: 0;
        }
    }
}

.button-row {
    margin: -16px 0 0 -16px;
    display: flex;
    flex-flow: row wrap;

    .button {
        margin: 16px 0 0 16px;
    }

    &--small {
        margin: -8px 0 0 -8px;

        .button {
            margin: 8px 0 0 8px;
        }
    }
}

.icon {
    display: block;
    width: 25px;
    height: 25px;

    &__mask {
        display: block;
        width: 100%;
        height: 100%;
        background-color: var(--icon-color, var(--color-brand-primary));
        transition-property: color, background-color;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
        mask: center no-repeat;
    }

    &--delete &__mask {
        $mask-image: functions.url-encode('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M9,3V4H4V6H5V19A2,2 0 0,0 7,21H17A2,2 0 0,0 19,19V6H20V4H15V3H9M7,6H17V19H7V6M9,8V17H11V8H9M13,8V17H15V8H13Z" /></svg>');
        mask-image: url($mask-image);
    }

    &--edit &__mask {
        $mask-image: functions.url-encode('<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"  width="24" height="24" viewBox="0 0 24 24"><path fill="#000000" d="M20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18,2.9 17.35,2.9 16.96,3.29L15.12,5.12L18.87,8.87M3,17.25V21H6.75L17.81,9.93L14.06,6.18L3,17.25Z" /></svg>');
        mask-image: url($mask-image);
    }
}
