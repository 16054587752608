@use '../../../css/variables.scss';

.form {
    fieldset {
        border: none;
        min-inline-size: auto;

        legend {
            font-size: 18px;
            color: var(--color-brand-dark);
            font-weight: variables.$font-weight-bold;
            text-transform: uppercase;
            margin-bottom: 16px;
        }

        + fieldset {
            margin-top: variables.$form-block-margin;
        }
    }
}
