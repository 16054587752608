@use "variables.scss";

@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700;900&display=swap');

body {
    font-family: Lato, sans-serif;
    font-size: 16px;
    font-weight: variables.$font-weight-regular;
    line-height: 1.375;
}

h1,
h2,
h3,
h4 {
    font-size: 18px;
    font-weight: variables.$font-weight-bold;
    text-transform: uppercase;
    letter-spacing: 0.32px;
    color: var(--color-brand-dark);

    margin: 0 0 1em;

    .subheader {
        display: block;
        margin-top: 16px;
        font-weight: variables.$font-weight-regular;
        font-size: 16px;
        letter-spacing: 0;
    }
}

h1 {
    font-weight: variables.$font-weight-regular;
    line-height: 30px;
    margin: 0;
    color: var(--color-brand-primary);
}

h2.highlight {
    font-size: 23px;
    letter-spacing: 0.53px;
}

h3.highlight {
    font-weight: variables.$font-weight-black;
    letter-spacing: 0.29px;
    color: var(--color-text);
}

h4 {
    font-size: 16px;
    font-weight: variables.$font-weight-black;
    color: var(--color-text);
}

p {
    margin: 0 0 1em;

    a {
        color: var(--color-brand-dark);
        text-decoration: underline;
    }
}

ul,
ol {
    margin: 0 0 1em;
    padding: 0 0 0 1.1em;

    li {
        margin-bottom: 0.5em;

        > ul,
        > ol {
            margin-top: 0.5em;
        }
    }
}

.text--right {
    text-align: right;
}

.text--notice {
    font-weight: bold;
    color: var(--color-brand-dark);
}

.text--info {
    font-size: 14px;
    color: var(--color-brand-dark);
}
