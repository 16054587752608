@use '../../../css/functions.scss';
@use '../../../css/variables.scss';

.pagination {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    &__list {
        @extend %reset-list;
        display: flex;
        flex-flow: row nowrap;
    }

    &__item + &__item {
        margin-left: 8px;
    }

    &__item {
        line-height: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 3px;
        background-color: var(--color-grey);
        color: var(--color-brand-dark);
        transition-property: background-color, color;
        transition-duration: 0.2s;
        transition-timing-function: ease-in-out;

        &--current,
        &:not(&--more):hover {
            background-color: var(--color-brand-primary);
            color: #ffffff;
        }

        &--current,
        &--more {
            width: 38px;
            height: 38px;
        }

        &--first,
        &--previous,
        &--next,
        &--last {
            width: 38px;
            background-color: transparent;

            a {
                background-color: var(--color-brand-primary);
                mask-size: 20px;
                mask-position: center;
                mask-repeat: no-repeat;
            }

            &:hover {
                background-color: var(--color-brand-primary);

                a {
                    background-color: #ffffff;
                }
            }
        }

        &--first + &--previous {
            margin-left: 0;
        }

        &--previous,
        &--next {
            width: 26px;
        }

        &--next + &--last {
            margin-left: 0;
        }

        &--first a {
            $arrow-first: functions.url-encode('<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><g fill="none" stroke="#000" stroke-width="6.7"><path d="M4.2 4.2v91.6M66.7 4.2 20.8 50l45.9 45.8"/><path d="M95.8 4.2 50 50l45.7 45.8"/></g></svg>');
            mask-image: url($arrow-first);
        }

        &--previous a {
            $arrow-first: functions.url-encode('<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M73 4.2 27 50l45.8 45.8" fill="none" stroke="#000" stroke-width="6.7"/></svg>');
            mask-image: url($arrow-first);
        }

        &--next a {
            $arrow-first: functions.url-encode('<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="M73 4.2 27 50l45.8 45.8" fill="none" stroke="#000" stroke-width="6.7"/></svg>');
            mask-image: url($arrow-first);
            transform: rotateY(180deg);
        }

        &--last a {
            $arrow-first: functions.url-encode('<svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" xml:space="preserve" fill-rule="evenodd" clip-rule="evenodd" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><g fill="none" stroke="#000" stroke-width="6.7"><path d="M4.2 4.2v91.6M66.7 4.2 20.8 50l45.9 45.8"/><path d="M95.8 4.2 50 50l45.7 45.8"/></g></svg>');
            mask-image: url($arrow-first);
            transform: rotateY(180deg);
        }

        @media (max-width: variables.$break-lg - 1) {
            /* hide first, last and more items on smaller screen */
            &--more,
            &--first,
            &--last {
                display: none;
            }

            /* hide first page number before the more item */
            &--first + &--previous + & {
                display: none;
            }

            /* hide the last page number after the second more */
            &--more + &:nth-last-child(3) {
                display: none;
            }
        }
    }

    &__link {
        display: flex;
        justify-content: center;
        align-items: center;
        text-decoration: none;
        width: 38px;
        height: 38px;
        color: inherit;
    }
}
