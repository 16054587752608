@use '../../../css/functions.scss';
@use '../../../css/variables.scss';

.file-list {
    @extend %reset-list;

    &__item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        min-height: 30px;

        &::before {
            $mask-image: functions.url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#000000" d="M14,2L20,8V20A2,2 0 0,1 18,22H6A2,2 0 0,1 4,20V4A2,2 0 0,1 6,2H14M18,20V9H13V4H6V20H18M12,19L8,15H10.5V12H13.5V15H16L12,19Z" /></svg>');
            content: '';
            display: block;
            width: 16px;
            height: 20px;
            background-color: var(--color-brand-dark);
            mask: url($mask-image) no-repeat center;
            mask-size: 20px;
            margin-right: 8px;
            flex-shrink: 0;
        }

        & + & {
            margin-top: 6px;
        }

        &--video::before {
            $mask-image: functions.url-encode('<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"> <path fill="#000000" d="M10,15L15.19,12L10,9V15M21.56,7.17C21.69,7.64 21.78,8.27 21.84,9.07C21.91,9.87 21.94,10.56 21.94,11.16L22,12C22,14.19 21.84,15.8 21.56,16.83C21.31,17.73 20.73,18.31 19.83,18.56C19.36,18.69 18.5,18.78 17.18,18.84C15.88,18.91 14.69,18.94 13.59,18.94L12,19C7.81,19 5.2,18.84 4.17,18.56C3.27,18.31 2.69,17.73 2.44,16.83C2.31,16.36 2.22,15.73 2.16,14.93C2.09,14.13 2.06,13.44 2.06,12.84L2,12C2,9.81 2.16,8.2 2.44,7.17C2.69,6.27 3.27,5.69 4.17,5.44C4.64,5.31 5.5,5.22 6.82,5.16C8.12,5.09 9.31,5.06 10.41,5.06L12,5C16.19,5 18.8,5.16 19.83,5.44C20.73,5.69 21.31,6.27 21.56,7.17Z" /> </svg>');
            mask-image: url($mask-image);
        }
    }

    &__link {
        color: var(--color-brand-dark);
        font-weight: variables.$font-weight-bold;

    }
}
