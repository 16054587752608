@use '../../../css/variables';
@use '../../../css/functions';

$arrow-right: functions.url-encode('<svg viewBox="0 0 17 32" xmlns="http://www.w3.org/2000/svg" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="m1 31 15-15L1 1" fill="none" stroke="#000" stroke-width="2"/></svg>');

.accordion {
    padding: 0 20px;
    position: relative;

    &__header {
        font-size: 16px;
        font-weight: variables.$font-weight-bold;
        cursor: pointer;
    }

    &__icon {
        position: absolute;
        top: 2px;
        left: 0;
        background-color: var(--color-brand-dark);
        mask: url($arrow-right) no-repeat center center;
        mask-size: 7px 14px;
        width: 20px;
        height: 20px;
        transition: transform 0.3s ease-in-out;
    }

    &.is-open &__icon {
        transform: rotateZ(90deg);
    }

    &__content {
        transform: rotateX(90deg);
        transform-origin: top left;
        height: 0;
        transition-property: height, transform;
        transition-duration: 0.3s;
        transition-timing-function: ease-in-out;
    }

    &.is-open &__content {
        transform: rotateX(0);
    }

    &.is-calculating &__content {
        position: absolute;
        visibility: hidden;
        height: auto;
    }
}
