@use '../../../css/functions.scss';

.footer-menu {
    font-size: 14px;
    line-height: 1;

    &__list {
        @extend %reset-list;
        padding-left: 22px;
    }

    &__item {
        color: var(--color-brand-dark);
    }

    &__link {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        height: 24px;
        color: inherit;
        text-decoration: none;

        &:before {
            content: '>';
            margin-right: 2px;
        }
    }
}
