$spacing: (
    s: 8px,
    m: 16px,
    l: 24px,
    xl: 32px,
    xxl: 48px
);

@each $name,$value in $spacing {
    .space-before--#{$name} {
        margin-top: $value;
    }
    .space-after--#{$name} {
        margin-bottom: $value;
    }
}

.align {
    &--center {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
    }
}
