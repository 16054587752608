@use '../../../css/variables';
@use '../../../css/functions';

$arrow-right: functions.url-encode('<svg viewBox="0 0 17 32" xmlns="http://www.w3.org/2000/svg" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="1.5"><path d="m1 31 15-15L1 1" fill="none" stroke="#000" stroke-width="2"/></svg>');

.sub-menu {
    margin-top: 4px;
    line-height: 24px;

    @media (min-width: variables.$break-md) {
        margin-top: 0;
    }

    &__list {
        @extend %reset-list
    }

    &__item {
        position: relative;
        color: var(--color-brand-dark);

        &.is-current,
        &:hover {
            > .sub-menu__link {
                background-color: var(--color-grey-light);
            }
        }
    }

    &__item.is-active > &__link {
        font-weight: variables.$font-weight-bold;
    }

    &__icon {
        position: absolute;
        top: 7px;
        left: 1px;
        background-color: var(--color-brand-dark);
        mask: url($arrow-right) no-repeat center center;
        mask-size: 7px 14px;
        width: 20px;
        height: 20px;
    }

    &__item.is-active.has-children &__icon {
        transform: rotateZ(90deg);
    }

    &__link {
        padding: 4px 0 4px 22px;
        justify-content: flex-start;
        align-items: center;
        display: flex;
        text-decoration: none;
        color: inherit;
    }

    &__list &__list &__link {
        padding-left: 36px;
    }

    &__list &__list &__link {
            height: 28px;
    }
}
