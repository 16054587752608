.header-meta {
  display: grid;
  grid-template-columns: 15em 15em;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    "account orders"
    "account documents";
}

.header-meta__item {
  padding: 0.75em;
  border: 0.0625em solid var(--color-gray-300);

  &.is-account {
    grid-area: account;

    display: grid;
    align-content: space-between;

    border-block: 0;
    border-inline-end: 0;
  }

  &.is-orders {
    grid-area: orders;
    border-block: 0;
  }

  &.is-documents {
    grid-area: documents;
    border-block-end: 0;
  }

  &.is-button {
    text-decoration: none;

    &:hover {
      background: var(--color-gray-300);
    }
  }
}
