.nav-sub {
  list-style: none;

  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  border: 0.0625em solid var(--color-gray-300);
}

.nav-sub__link {
  display: block;
  padding-block: 0.375em 0.5em;
  padding-inline: 1em;

  color: var(--color-gray-600);
  text-decoration: none;

  // Pull background above parent border.
  margin-block: -0.0625em;

  &:hover,
  .is-active > & {
    color: var(--color-gray-900);
    background: var(--color-gray-300);
  }
}
