@use '../../../css/variables.scss';

.textmedia {
    &__media {
        img {
            border-radius: 3px;
        }
    }

    &__text {
        display: flex;
        flex-flow: column;
        overflow: hidden;

        :last-child {
            margin-bottom: 0;
        }
    }

    &--left,
    &--right {
        @media (min-width: variables.$break-md) {
            display: flex;
            flex-flow: row nowrap;

            &.textmedia--floating {
                display: block;
                overflow: hidden;

                .textmedia__media {
                    margin-bottom: 28px;
                }

                .textmedia__text {
                    display: block;
                    overflow: visible;
                }
            }
        }
    }

    &--left.textmedia--floating &__media {
        @media (min-width: variables.$break-md) {
            float: left;
        }
    }

    &--right.textmedia--floating &__media {
        margin-bottom: 28px;

        @media (min-width: variables.$break-md) {
            float: right;

        }
    }

    &--left &__media {
        margin-bottom: 28px;
        flex-shrink: 0;

        @media (min-width: variables.$break-md) {
            margin-right: 28px;
            margin-bottom: 0;
            max-width: 50%;
        }
    }

    &--right &__media {
        margin-top: 28px;
        flex-shrink: 0;

        @media (min-width: variables.$break-md) {
            margin-left: 28px;
            margin-top: 0;
            margin-bottom: 0;
            max-width: 50%;
        }
    }

    &--top &__media,
    &--top-left &__media,
    &--top-right &__media {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;

        + .textmedia__text {
            margin-top: 28px;
        }
    }

    &--bottom &__media,
    &--bottom-left &__media,
    &--bottom-right &__media {
        margin-top: 28px;
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
    }

    &--top-left &__media,
    &--bottom-left &__media {
        justify-content: flex-start;
    }

    &--top-right &__media,
    &--bottom-right &__media {
        justify-content: flex-end;
    }

    &__link {
        display: block;
    }
}

.figure {
    display: table;

    &__caption {
        display: table-caption;
        caption-side: bottom;
        margin-top: 4px;

        font-size: 14px;
        font-weight: variables.$font-weight-bold;
    }
}

.news {
    @media (min-width: variables.$break-md) {
        display: flex;
        &:not(.textmedia--left) {
            flex-flow: column;
        }
        height: 100%;
    }

    .textmedia__text {
        flex-grow: 1;

        time {
            font-size: 14px;
            color: var(--color-brand-light);
            margin-bottom: 3px;
        }

        p {
            line-height: 22px;
            &:last-of-type {
                margin-bottom: 16px;
            }
        }

        .button {
            margin-top: auto;
            align-self: center;
            justify-content: flex-end;
        }

    }

    h2 {
        margin-bottom: 10px;
    }

    &.textmedia--top .textmedia__text {
        // Decrease space between image and text/date in news teaser
        margin-top: 8px;
    }

}
