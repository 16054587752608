.header {
  margin-block-end: 2em;
}

.header__upper {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.header__logo {
  margin-block: 0.75em;
}
