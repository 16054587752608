@use '../../../css/functions.scss';
@use '../../../css/variables.scss';

.search-result {
    &__list {
        @extend %reset-list;
        margin-top: 32px;
    }

    &__item + &__item {
        margin-top: 32px;
    }

    &__header {
        font-size: 16px;
        margin-bottom: 0.5em;
    }

    &__link {
        text-decoration: none;
    }

    &__highlight {
        color: var(--color-attention);
    }
}
