@use "variables.scss";
@use "functions.scss";

*,
::before,
::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

body {
    margin: 0;
}

img {
    max-width: 100%;
    height: auto;
    vertical-align: middle;
}

.page {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: 0 minmax(0, auto) 32px 0;
    grid-template-rows: 51px repeat(3, auto);
    margin-top: 16px;

    @media (min-width: variables.$break-md) {
        grid-template-columns: minmax(0, auto) 200px minmax(auto, 984px) minmax(0, auto);
        grid-template-rows: 51px 100px auto minmax(0, 1fr)
    }

    @media (min-width: variables.$break-lg) {
        grid-column-gap: 24px;
        grid-template-rows: 100px 30px auto minmax(0, 1fr);
        margin-top: 0;
    }

    @media (min-width: variables.$break-xl) {
        grid-column-gap: 32px;
    }
}

.logo {
    grid-column: 2;
    grid-row: 1;
    height: 51px;
    z-index: 3;

    @media (min-width: variables.$break-lg) {
        margin-top: 28px;
        margin-left: 23px;
    }
}

.main-menu {
    grid-column: 2 / span 2;
    grid-row: 1;

    @media (min-width: variables.$break-md) {
        grid-column: 2 / span 2;
        grid-row: 2;
    }

    @media (min-width: variables.$break-lg) {
        grid-column: 3;
        grid-row: 1;
    }
}

.search {
    display: none;

    @media (min-width: variables.$break-md) {
        display: block;
        grid-column: 3;
        grid-row: 1;
        margin-left: auto;
    }

    @media (min-width: variables.$break-lg) {
        grid-column: 2;
        grid-row: 2;
        margin: 0;
    }

    .form__field {
        position: relative;

        &::before {
            $search-icon: functions.url-encode('<svg  xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24"><path d="M9.516 14.016q1.875 0 3.188-1.313t1.313-3.188-1.313-3.188-3.188-1.313-3.188 1.313-1.313 3.188 1.313 3.188 3.188 1.313zM15.516 14.016l4.969 4.969-1.5 1.5-4.969-4.969v-0.797l-0.281-0.281q-1.781 1.547-4.219 1.547-2.719 0-4.617-1.875t-1.898-4.594 1.898-4.617 4.617-1.898 4.594 1.898 1.875 4.617q0 0.984-0.469 2.227t-1.078 1.992l0.281 0.281h0.797z"></path> </svg>');
            content: '';
            display: block;
            background-color: var(--color-brand-primary);
            width: 22px;
            height: 22px;
            mask: url($search-icon) no-repeat;
            position: absolute;
            top: 5px;
            left: 4px;
        }
    }

    .form__control {
        min-height: auto;
        height: 30px;
        padding: 4px 4px 4px 26px;
    }
}

.sub-menu {
    display: none;
    grid-column: 2;
    grid-row: 3;

    @media (min-width: variables.$break-md) {
        display: block;
        margin-bottom: variables.$side-menu-spacing;
    }
}

.footer {
    grid-column: 2 / span 2;
    grid-row: 3;

    display: flex;
    flex-flow: column;
    margin-top: calc(#{variables.$side-menu-spacing} - 16px);

    > * {
        &::before {
            content: '';
            display: block;
            width: 100%;
            height: 1px;
            background-color: var(--color-grey);
            margin-bottom: variables.$side-menu-spacing;
        }

        &:nth-child(n+2) {
            margin-top: variables.$side-menu-spacing;
        }
    }

    @media (min-width: variables.$break-sm) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 16px;
        border-top: 1px solid var(--color-grey);
        margin-top: calc(#{variables.$side-menu-spacing} - 16px);

        > * {
            &:nth-child(n+2) {
                margin-top: variables.$side-menu-spacing;
            }

            &::before {
                display: none;
            }
        }

        .language-menu {
            grid-column: 1;
            grid-row: 2;
        }

        .footer-menu {
            grid-row: 1 / span 2;
        }
    }

    @media (min-width: variables.$break-md) {
        display: flex;
        grid-column: 2;
        grid-row: 4;
        grid-gap: 0;
        border-top: none;
        margin-top: -16px;

        > *::before {
            display: block;
        }
    }
}

.content {
    grid-column: 2 / span 2;
    grid-row: 2;
    overflow: hidden;

    @media (min-width: variables.$break-md) {
        grid-column: 3;
        grid-row: 3 / span 2;
        margin-bottom: 32px;
    }

    @media (min-width: variables.$break-lg) {
        grid-row: 2 / span 3;
    }

    .title {
        font-size: 16px;
        color: var(--color-brand-dark);
        display: flex;
        flex-flow: column nowrap;

        @media (min-width: variables.$break-sm) {
            flex-direction: row;
            justify-content: space-between;
        }
    }

    .date {
        color: var(--color-grey-dark);
        font-size: 16px;
        letter-spacing: 0.29px;

        @media (min-width: variables.$break-sm) {
            margin-left: 16px;
            color: var(--color-brand-primary);
        }
    }
}
