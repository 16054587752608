.nav-main {
  list-style: none;

  position: relative;

  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  background: var(--color-gray-50);
  border: 0.0625em solid var(--color-gray-300);
  border-block-start: 0;
}

.nav-main__sub {
  position: absolute;
  inset-inline-start: -0.0625em;
  inset-block-start: 100%;
}

.nav-main__link {
  display: block;
  padding-block: 0.75em 0.875em;
  padding-inline: 1em;

  // Pull background above parent border.
  margin-block: -0.0625em;

  color: inherit;
  text-decoration: none;

  &:hover,
  .is-active > & {
    color: hsl(0deg 0% 100%);
    background: var(--color-brand-accessible);
  }
}
