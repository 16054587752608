.contact {
    color: var(--color-brand-dark);

    p {
        overflow: hidden;
        text-overflow: ellipsis;
    }

    a {
        color: var(--color-brand-dark);
        white-space: nowrap;
    }
}
