%reset-list {
    margin: 0;
    padding: 0;
    list-style: none;

    > li {
        margin: 0;
    }
}

@function str-replace($string, $search, $replace: '') {
    $index: str-index($string, $search);
    @if $index {
        @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
    }
    @return $string;
}

$encoding-reference: (
        ('<','%3C'),
        ('>','%3E'),
        //('"','%22'),  // Replace " with ' because that's shorter than %22 and normally working
        //('"','\''),
        ('#','%23'),
        ('&','%26')
);

@function url-encode($code, $prefix: 'data:image/svg+xml,') {
    @each $char, $encoded in $encoding-reference {
        $code: str-replace($code, $char, $encoded);
    }
    @return $prefix + $code;
}
