$color-text: #191919;
$color-brand-dark: #2e6589;

:root {
    --color-brand-primary: #5b8eba;
    --color-brand-light: #9ec3de;
    --color-brand-dark: #{$color-brand-dark};
    --color-grey: #d9d9d9;
    --color-grey-light: #ecedef;
    --color-grey-dark: #848d93;
    --color-text: #{$color-text};
    --color-attention: #ec008c;
}

$break-sm: 540px;
$break-md: 768px;
$break-lg: 1024px;
$break-xl: 1280px;

$breakpoints: (
    sm: $break-sm,
    md: $break-md,
    lg: $break-lg,
    xl: $break-xl
);

$font-weight-regular: 400;
$font-weight-bold: 700;
$font-weight-black: 900;

$form-block-margin: 32px;
$form-grid-gap: 16px;

$side-menu-spacing: 24px;
